export function getjwt(params) {
  return request(`/common/v1/ws/jwt/anonymous`, {
    method: 'GET',
    params
  })
}

// NOTE: 在server中调用该接口时，localstorage可能失效。
export function getSpeicalStyleMap() {
  const local = window?.localStorage?.getItem('specialStyleMap')
  if (local) {
    return Promise.resolve(JSON.parse(local))
  } else {
    return oRequest(`/user/system-config/v1/config`, {
      method: 'GET'
    }).then(({ specialStyleMap }) => {
      window.localStorage.setItem('specialStyleMap', JSON.stringify(specialStyleMap))
      return specialStyleMap
    })
  }
}

export function getCommonDictionary(refresh) {
  const local = window?.localStorage?.getItem('commonDictionary')
  const rateList = useCookie('SDT_RateList')
  const timestamp = JSON.parse(local)?.timestamp
  const curTimestamp = new Date().getTime()
  const differenceTime = 5 * 60 * 1000 //5分钟
  const bIsRefresh = refresh == 1 ? true : false

  if (local && timestamp && curTimestamp - timestamp < differenceTime && !bIsRefresh) {
    rateList.value = JSON.parse(local).rates
    return Promise.resolve(JSON.parse(local))
  } else {
    return oRequest(`/user/system-config/v1/config`, {
      method: 'GET'
    }).then(res => {
      res.timestamp = new Date().getTime()
      rateList.value = res.rates
      window.localStorage.setItem('commonDictionary', JSON.stringify(res))
      return res
    })
  }
}
